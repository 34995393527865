
                                        @import "~coreScss/variables/global-variables.scss";
                                        @import "~coreScss/mixins/global-mixins.scss";
                                    

    .c-image-module {
        position: relative;
        height: 100%;

        > a {
            display: block;
        }

        &__img-wrapper {
            position: relative;
        }

        &__img {
            display: block;
            width: 100%;

            &--with-spacer {
                margin-bottom: $nu-spacer-2;
            }

            &--stretch {
                object-fit: cover;
                height: 100%;
            }
        }
    }
