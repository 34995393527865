
                                        @import "~coreScss/variables/global-variables.scss";
                                        @import "~coreScss/mixins/global-mixins.scss";
                                    

.c-app-row {
    --c-app-row-animation-state: paused;

    $this: &;
    $screen: #{$this}__screen;
    $touch:  #{$this}__touch;

    $number-of-screens: 8;
    $z-frame: #{$number-of-screens + 10};
    $z-touch: #{$number-of-screens + 11};

    $animation-duration:           17s;
    $tap-duration:                0.5s;
    $screen-transition-duration:  0.5s;

    $device-height-base:  717px;
    $device-width-base:   332px;
    $frame-thickness:       6px;
    $touch-diameter:       48px;

    background-color: $nu-white;

    &__device-frame,
    &__device-container {
        border-radius: 42px;
    }

    &__device-row::before,
    &__device-frame,
    &__screen {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        content: ' ';
    }

    &__touch,
    &__screen {
        animation-duration: $animation-duration;
        animation-iteration-count: infinite;
        animation-play-state: var(--c-app-row-animation-state);
        will-change: animation-play-state, --c-app-row-animation-state;
    }

    &__device-frame,
    &__touch::after {
        @include dropshadow;
    }

    &__content {
        margin: $nu-spacer-10 0;

        @include breakpoint(large) {
            display: flex;
            justify-content: center;
            flex-direction: column;
        }
    }

    &__touch,
    &__device-row {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__app-store-logo {
        g {
            fill: $nu-black;
        }
    }

    &__device-row {
        height: 450px;
        margin-bottom: $nu-spacer-10;
        position: relative;

        @include breakpoint(medium) {
            height: 717px;
        }
        @include breakpoint(large) {
            height: 506px;
            margin-top: $nu-spacer-10;
        }
        @include breakpoint(extra-large) {
            height: 615px;
        }

        // blob
        &::before {
            background-image: var(--app-row-blob);
            height: 430px;
            width:  340px;
            background-position: center;
            background-repeat:   no-repeat;
            background-size:     100%;

            @include breakpoint(medium)      {
                height: 729px;
                width:  582px;
            }

            @include breakpoint(large)       {
                height: 500px;
                width:  400px;
            }

            @include breakpoint(extra-large) {
                height: 609px;
                width:  449px;
            }

        }
    }

    &__device-container {
        @include breakpoint(extra-small) { transform: scale(0.63) }
        @include breakpoint(medium)      { transform: scale(1)    }
        @include breakpoint(large)       { transform: scale(0.7) }
        @include breakpoint(extra-large) { transform: scale(0.86) }

        height: $device-height-base + $frame-thickness;
        width:  $device-width-base  + $frame-thickness;
        overflow: hidden;
        position: relative;
    }

    &__device-frame {
        @include dimensions(100%);

        border: $frame-thickness solid $nu-secondary-60;
        z-index: $z-frame;

        // display cutout
        &::after {
            background-color: $nu-secondary-60;
            position: absolute;
            transform: translateY(-1px);
            top: 0;
            left: 0;
            right: 0;
            margin: auto;
            height: 22px;
            width: 152px;
            content: '';

            border-radius: 0 0 16px 16px;
        }
    }

    &__screen {
        animation-timing-function: steps(1);

        height: $device-height-base;
        width:  $device-width-base;
        background-position: top;
        background-repeat:   no-repeat;
        background-size:     100% auto;
    }

    &__touch {
        @include dimensions($touch-diameter);

        // center the touch on the bottom left corner so the transforms determining
        // touch placement can be used as (x, y) pairs on a graph overlaying the container
        $origin-offset: -#{($touch-diameter /  2)};

        --indicator-transform: scale(1);

        animation-iteration-count: infinite;
        animation-name: taps;
        transition-timing-function: ease;

        position: absolute;
        bottom:   $origin-offset;
        left:     $origin-offset;
        z-index:  $z-touch;

        &::after {
            @include dimensions(100%);

            background: rgba($nu-gray, 0.3);
            border-radius: 50%;
            border: 1px solid $nu-secondary;
            content: ' ';
            transform: var(--indicator-transform);
        }
    }

    /*****************************
     *** animation definitions ***
     *****************************/

    $offscreen-right: translateX(calc( 200% + 2px));
    $offscreen-down:  translateY(calc( 100% + 2px));
    $offscreen-left:  translateX(calc(-100% - 2px));
    $onscreen:        translate(0, 0);

    @function timestamp($seconds) {
        // convert timestamp from seconds to percentage of total animation duration usable in @keyframes
        @return '#{$seconds * 100 / $animation-duration}%';
    }

    @function app-transition($seconds) {
        @return timestamp($seconds + $screen-transition-duration);
    }

    @mixin tap($time, $x, $y) {
        $fade-in-start:  $time - 0.4s;
        $fade-in-end:    $time - 0.2s;

        $touch-start:    $time;
        $touch-end:      $time + 0.2s;

        $fade-out-start: $time + 0.4s;
        $fade-out-end:   $time + 0.6s;

        $offset: $frame-thickness / 2;
        $xy: translate(#{$x - $offset}, -#{$y - $offset});

        $tap-down: scale(0.8);
        $tap-up:   scale(1);

        #{timestamp($fade-in-start)} {
            --indicator-transform: #{$tap-up};
            animation-timing-function: steps(1);
            transform: $xy;
            opacity: 0;
        }
        #{timestamp($fade-in-end)} {
            --indicator-transform: #{$tap-up};
            opacity: 1;
        }
        #{timestamp($touch-start)} {
            --indicator-transform: #{$tap-down};
            opacity: 1;
        }
        #{timestamp($touch-end)} {
            --indicator-transform: #{$tap-up};
            opacity: 1;
        }
        #{timestamp($fade-out-start)} {
            --indicator-transform: #{$tap-up};
            transform: $xy;
            opacity: 1;
        }
        #{timestamp($fade-out-end)} {
            --indicator-transform: #{$tap-up};
            transform: $xy;
            opacity: 0;
        }
    }

    @mixin touchscroll($start, $end, $x, $y-start, $y-end) {
        $fade-in-start:  $start - 0.45s;
        $fade-in-end:    $start - 0.25s;
        $touch-start:    $start;
        $touch-end:      $end;
        $fade-out-start: $end + 0.25s;
        $fade-out-end:   $end + 0.45s;

        $xy-start: translate(#{$x}, -#{$y-start});
        $xy-end:   translate(#{$x}, -#{$y-end});

        $tap-down: scale(0.8);
        $tap-up:   scale(1);

        #{timestamp($fade-in-start)} {
            --indicator-transform: #{$tap-up};
            animation-timing-function: steps(1);
            transform: $xy-start;
            opacity: 0;
        }
        #{timestamp($fade-in-end)} {
            --indicator-transform: #{$tap-up};
            transform: $xy-start;
            opacity: 1;
        }
        #{timestamp($start)} {
            --indicator-transform: #{$tap-down};
            transform: $xy-start;
            opacity: 1;
        }
        #{timestamp($end)} {
            --indicator-transform: #{$tap-down};
            transform: $xy-end;
            opacity: 1;
        }
        #{timestamp($fade-out-start)} {
            --indicator-transform: #{$tap-up};
            transform: $xy-end;
            opacity: 1;
        }
        #{timestamp($fade-out-end)} {
            --indicator-transform: #{$tap-up};
            transform: $xy-end;
            opacity: 0;
        }
    }

    @mixin slide-in($time) {
        #{timestamp($time)} {
            animation-timing-function: ease;
            transform: $offscreen-right;
        }
        #{app-transition($time)} {
            transform: $onscreen;
            animation-timing-function: steps(1);
        }
    }

    @mixin slide-out($time) {
        #{timestamp($time)} {
            animation-timing-function: ease;
            transform: $onscreen;
        }
        #{app-transition($time)} {
            transform: $offscreen-left;
            animation-timing-function: steps(1);
        }
    }

    @mixin slide-up($time) {
        #{timestamp($time)} {
            animation-timing-function: ease;
            transform: $offscreen-down;
        }
        #{app-transition($time)} {
            animation-timing-function: steps(1);
            transform: $onscreen;
        }
    }

    @mixin fade-in($time) {
        #{timestamp($time)} {
            animation-timing-function: ease;
            transform: $onscreen;
            opacity: 0;
        }
        #{app-transition($time)} {
            animation-timing-function: steps(1);
            opacity: 1;
        }
    }

    @mixin slide-down($time) {
        #{timestamp($time)} {
            animation-timing-function: ease;
            transform: $onscreen;
        }
        #{app-transition($time)} {
            animation-timing-function: steps(1);
            transform: $offscreen-down;
        }
    }

    @for $index from 1 through $number-of-screens {
        $screen-at-index: #{$screen}--#{$index};

        #{$screen-at-index} {
            animation-name: device-screen-#{$index};
            background-image: var(--app-row-screen-#{$index});
        }

        @if      $index == 5 { #{$screen-at-index} { z-index: 3      } }
        @else if $index == 7 { #{$screen-at-index} { z-index: 8      } }
        @else if $index == 8 { #{$screen-at-index} { z-index: 7      } }
        @else                { #{$screen-at-index} { z-index: $index } }
    }

    // listings landing
    @keyframes device-screen-1 {
        0% {
            opacity: 1;
            transform: $onscreen;
            transition-timing-function: steps(1);
            z-index: 1;
        }
        #{timestamp(3s)} {
            opacity: 0;
            transition-timing-function: steps(1);
            z-index: 1;
        }
        #{timestamp(12.9s)} {
            z-index: 9; // needs to slide over top of the profile page
        }

        @include fade-in(16.5s);

        100% {
            opacity: 1;
            transform: $onscreen;
            z-index: 9;
        }
    }

    // blank listing template
    @keyframes device-screen-2 {
        0% { transform: $offscreen-down }

        @include slide-up(1.5s);

        #{timestamp(4s)} { transform: $offscreen-down }
        100%             { transform: $offscreen-down }
    }

    //media uploader -- blank
    @keyframes device-screen-3 {
        0% { transform: $offscreen-down }

        @include slide-up(3.5s);

        #{timestamp(5s)} { transform: $offscreen-left }
        100%             { transform: $offscreen-left }
    }

    // media uploader w/ selection
    @keyframes device-screen-4 {
        0%               { transform: $offscreen-left }
        #{timestamp(5s)} { transform: $onscreen       }

        @include slide-down(6.5s);

        100% { transform: $offscreen-left }
    }

    // listing template w/ media filled
    @keyframes device-screen-5 {
        0%                  { transform: $offscreen-left }
        #{timestamp(5s)}    { transform: $onscreen       }
        #{timestamp(8.5s)}  { transform: $offscreen-left }
        100%                { transform: $offscreen-left }
    }

    // listing template w/ media and text filled
    @keyframes device-screen-6 {
        0% {
            background-position-y: top;
            transform: $offscreen-down;
        }
        #{timestamp(8s)} {
            transform: $onscreen;
        }
        #{timestamp(9s)} {
            animation-timing-function: ease;
            background-position-y: top;
        }
        #{timestamp(10.5s)} {
            animation-timing-function: ease;
            background-position-y: bottom;
            transform: $onscreen;
        }

        @include slide-out(12.25s);

        100% {
            animation-timing-function: steps(1);
            background-position-y: bottom;
            transform: $offscreen-down;
        }
    }

    // success screen
    @keyframes device-screen-7 {
        0% { transform: $offscreen-down }

        @include slide-in(12.25s);
        @include slide-down(14.25s);

        100% { transform: $offscreen-down }
    }

    // profile page
    @keyframes device-screen-8 {
        0% {
            opacity: 0;
            transform: $offscreen-left;
        }
        #{timestamp(13s)} {
            opacity: 1;
            transform: $onscreen;
        }
        100% {
            opacity: 1;
            transform: $onscreen;
        }
    }

    @keyframes taps {
        0% {
            animation-timing-function: steps(1);
            opacity: 0;
            transform: translate(0, 0);
        }

        @include tap(1s,    173px,  215px);

        @include tap(3s,     74px,  303px);

        @include tap(4.5s,  280px, 258px);

        @include tap(6s, 304px, 665px);

        @include tap(7.5s, 173px, 246px);

        @include touchscroll(9s, 10.5s, 292px, 40px, 690px);

        @include tap(11.6s, 250px,  64px);

        @include tap(13.5s, 173px, 100px);

        @include tap(16s,   173px,  44px);

        100% {
            animation-timing-function: steps(1);
            opacity: 0;
            transform: translate(0, 0);
        }
    }
}
