
                                        @import "~coreScss/variables/global-variables.scss";
                                        @import "~coreScss/mixins/global-mixins.scss";
                                    

    .c-brands-row {
        $this: &;

        &__section-label {
            display: block;
        }

        &__text-container {
            text-align: center;
            max-width: 640px;
            margin: auto;
        }

        &__logos-container {
            display: grid;

            @include breakpoint(large){
                max-width: map-get($breakpoints, extra-large);
                margin: auto;
            }
        }

        &__logo-row {
            display: grid;
            grid-template-columns: repeat(12, 1fr);
            gap: $nu-spacer-1;
            width: 100%;
            justify-content: center;
            justify-items: center;

            @include breakpoint(medium) {
                gap: $nu-spacer-2;
            }
        }

        &__logo-cell {
            grid-column: span 6;

            @include breakpoint(medium) {
                grid-column: span 3;
            }

            &--first-row {
                grid-column-start: 3;
                grid-column-end: span 8;

                @include breakpoint(medium) {
                    grid-column: span 4;
                }
            }
        }

        &__logo-wrapper {
            height: auto;
            position: relative;
            overflow: visible;

            // Create new stacking context to prevent hover blob from clipping above logos.
            // Used in conjunction with the ::before pseudoelement z-index
            transform: translate3d(0, 0, 0);

            @include breakpoint(medium) {
                width: 100%;
                display: flex;
                justify-content: center;
            }

            @include breakpoint(medium) {
                &:hover,
                &[data-focus-visible-added] {
                    &::before {
                        opacity: 1;
                    }
                }

                &::before {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    margin: auto;
                    content: ' ';
                    background-image: url('../../../../core-lib/images/svgs/homepage__blob--hover.svg');
                    background-position: center;
                    background-size: contain;
                    background-repeat: no-repeat;
                    height: 100%;
                    width: 60%;
                    opacity: 0;
                    transition: opacity 0.2s ease;
                    z-index: -1;
                }
            }
        }

        &__logo {
            filter: $nu-filter-color;
            -webkit-filter: $nu-filter-color;

            height: auto;
            width: 100%;
        }
    }
