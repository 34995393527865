@mixin account-management($base-class) {
    #{$base-class} {
        &__table-section {
            margin-top: $nu-spacer-2;
        }
    }
}

@mixin modal-drawer($base-class) {
    #{$base-class} {
        &__buttons {
            align-items: center;
            display: flex;
            flex-direction: column;
        }

        &__cancel-button {
            margin-left: $nu-spacer-2;
        }
    }
}

@mixin checkboxHover($relationship: 'self') {
    $box: '.c-checkbox__input:not(:disabled) ~ .c-checkbox__container > .c-checkbox__box';
    $label: #{$box ' ~ .c-checkbox__label'};

    $border: 1px solid $nu-primary;

    @include non-touch-device {
        @if $relationship == 'child' {
            &:hover {
                #{$box} {
                    border: $border;
                }

                #{$label} {
                    color: $nu-black;
                }
            }
        }
        @else if $relationship == 'sibling' {
            &:hover ~ .c-checkbox {
                #{$box} {
                    border: $border;
                }

                #{$label} {
                    color: $nu-black;
                }
            }
        }
        @else if $relationship == 'internal' {
            &:hover {
                #{$box} {
                    border: $border;
                }

                #{$label} {
                    color: $nu-black;
                }
            }
        }
        @else {
            @error 'A valid relationship must be provided for the hover style';
        }
    }
}

@mixin dimensions($d) {
    height: $d;
    width: $d;
}

@mixin css-has-supported {
    @supports (selector(:has(*))) {
        @content;
    }
}

/* Safari 11+ */
@mixin safari-outline {
    @media not all and (min-resolution:.001dpcm) {
        @supports (-webkit-appearance:none) and (stroke-color:transparent) {
            @content;
        }
    }
}
