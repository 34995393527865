
                                        @import "~coreScss/variables/global-variables.scss";
                                        @import "~coreScss/mixins/global-mixins.scss";
                                    

    .c-social-grid-module {
        &__column-gap {
            grid-column-gap: $nu-spacer-1 !important;

            @include breakpoint(medium) {
                grid-column-gap: $nu-spacer-2 !important;
            }
        }

        &__link {
            aspect-ratio: 1;
        }

        &__img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;

            @include breakpoint(medium) {
                margin-bottom:  $nu-spacer-1 !important;
            }
        }
    }
