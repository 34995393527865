
                                        @import "~coreScss/variables/global-variables.scss";
                                        @import "~coreScss/mixins/global-mixins.scss";
                                    

    .c-zebra-table {
        $this: &;
        width: calc(100% - (2 * #{$nu-spacer-2}));
        margin: 0 $nu-spacer-2;

        @include breakpoint(medium) {
            margin: 0;
            width: 100%;
        }

        &__header {
            background-color: $nu-gray--light;
            height: 48px;
            vertical-align: middle;

            &--left {
                text-align: left;

                &:first-child {
                    padding-left: $nu-spacer-3;
                }
            }

            &--hidden {
                display: none;

                @include breakpoint(medium) {
                    display: table-header-group;
                }
            }
        }

        &__row {
            color: $nu-black;

            &:nth-child(n) {
                background-color: $nu-white;
            }

            &:nth-child(2n) {
                background-color: $nu-gray--light;
            }

            &--white {
                background-color: $nu-white;

                &:nth-child(2n) {
                    background-color: $nu-white;
                }
            }
        }

        &__data {
            height: 48px;
            padding: $nu-spacer-1pt5 $nu-spacer-2;
            text-align: center;

            &--left {
                text-align: left;
            }

            &--stackable {
                display: block;
                height: auto;
                padding: 0 $nu-spacer-2;
                text-align: left;

                &:first-child {
                    padding-top: $nu-spacer-2;
                }

                &:last-child {
                    padding-bottom: $nu-spacer-2;
                }

                @include breakpoint(medium) {
                    display: table-cell;
                    height: $nu-spacer-7;
                    padding: $nu-spacer-1pt5 $nu-spacer-2;
                    text-align: center;
                }

                #{$this}__cell-text {
                    &--desktop {
                        display: none;

                        @include breakpoint(small) {
                            display: block;
                        }
                    }

                    &--mobile {
                        display: block;

                        @include breakpoint(small) {
                            display: none;
                        }
                    }
                }
            }
        }

        &__cell-text--mobile {
            font-size: 1.2rem;
        }
    }
