
                                        @import "~coreScss/variables/global-variables.scss";
                                        @import "~coreScss/mixins/global-mixins.scss";
                                    

    .c-five-up-grid {
        column-gap: $nu-spacer-0pt5;
        row-gap: $nu-spacer-0pt5;
        margin: $nu-spacer-10 auto;
        max-width: map-get($breakpoints, extra-large);

        @include breakpoint(medium) {
            margin: $nu-spacer-16 auto;
        }

        &__copy-container {
            margin-bottom: $nu-spacer-5;
            text-align: center;

            @include breakpoint(medium) {
                margin-bottom: $nu-spacer-8;
            }
        }

        &__list {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            column-gap: $nu-spacer-0pt5;
            row-gap: $nu-spacer-0pt5;
        }
    }
