
                                        @import "~coreScss/variables/global-variables.scss";
                                        @import "~coreScss/mixins/global-mixins.scss";
                                    

    .hero-video-module {
        position: relative;
        height: 75vh;
        min-height: 500px;
        width: 100%;

        > a {
            display: block;
        }

        &__img {
            width: 100%;
            display: block;

            @include breakpoint(medium) {
                object-fit: cover;
                position: absolute;
                height: 100%;
            }

            &--with-spacer {
                margin-bottom: $nu-spacer-2;
            }
        }

        &__hero-media {
            object-fit: cover;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            height: 100%;
            width: 100%;
        }
    }
