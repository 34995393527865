
                                        @import "~coreScss/variables/global-variables.scss";
                                        @import "~coreScss/mixins/global-mixins.scss";
                                    

    .c-carousel-module {
        &__slide {
            white-space: normal;
        }
    }
