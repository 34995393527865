
                                        @import "~coreScss/variables/global-variables.scss";
                                        @import "~coreScss/mixins/global-mixins.scss";
                                    

    .c-pagination-button {
        $this: &;

        height: 40px;
        width: 40px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        transition: opacity 0.2s ease;
        border-radius: 2px;
        background-color: $nu-secondary;
        cursor: pointer;

        &--light {
            background-color: $nu-white;
        }

        &--disabled {
            cursor: default;
        }

        &--disabled-hidden {
            opacity: 0;
            pointer-events: none;
        }

        &--translucent {
            background-color: rgba($nu-white, 0.8);
        }
    }
