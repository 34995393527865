
                                        @import "~coreScss/variables/global-variables.scss";
                                        @import "~coreScss/mixins/global-mixins.scss";
                                    

    .c-accordion-group {
        &__header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: $nu-spacer-3;
            border-bottom: 1px solid $nu-white;
        }

        &__text {
            margin-right: $nu-spacer-2;
        }

        &__link {
            flex-shrink: 0;
        }
    }
