
                                        @import "~coreScss/variables/global-variables.scss";
                                        @import "~coreScss/mixins/global-mixins.scss";
                                    

    .c-accordion {
        border-bottom: 1px solid $nu-white;

        &__header {
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: $nu-spacer-1pt5 0;
        }

        &__header-icon {
            margin: 0 $nu-spacer-2 0 $nu-spacer-3;
            height: 100%;
        }

        &__body {
            padding-bottom: $nu-spacer-1pt5;
        }
    }
