
                                        @import "~coreScss/variables/global-variables.scss";
                                        @import "~coreScss/mixins/global-mixins.scss";
                                    

    .c-editorial-image-text {

        &--center,
        &--top,
        &--bottom {
            position: absolute;
            width: 100%;
        }

        &--center {
            top: 50%;
            transform: translateY(-50%);
        }

        &--top {
            top: 10%;
            transform: translateY(-10%);
        }

        &--bottom {
            top: 90%;
            transform: translateY(-90%);
        }

        &--not-clickable {
            pointer-events: none;
        }

        &--1-up {
            padding: 0 8%;

            @include breakpoint(medium) {
                padding: 0 20%;
            }
        }

        &--2-up {
            padding: 0 8%;

            @include breakpoint(medium) {
                padding: 0 16%;
            }
        }

        &--3-up {
            padding: 0 12%;
        }

        &--4-up {
            padding: 0 8%;
        }
    }
