
                                        @import "~coreScss/variables/global-variables.scss";
                                        @import "~coreScss/mixins/global-mixins.scss";
                                    

    .c-two-up-row-module {
        max-width: map-get($breakpoints, extra-large);
        margin: 0 auto;
        padding-bottom: $nu-spacer-8;

        @include breakpoint (large) {
            padding-bottom: $nu-spacer-12;
        }

        &__left-image {
            margin-bottom: $nu-spacer-3;

            @include breakpoint(large) {
                margin-bottom: 0;
            }
        }

        &__heading {
            @include heading-3;

            @include breakpoint(medium) {
                @include heading-1;
            }
        }

        &__centered-content {
            margin: auto;
        }

        &__right-image {
            display: none;
            margin-bottom: $nu-spacer-3;

            @include breakpoint(large) {
                display: block;
            }
        }
    }
