
                                        @import "~coreScss/variables/global-variables.scss";
                                        @import "~coreScss/mixins/global-mixins.scss";
                                    

    .c-content-scroll-fade-in {
        opacity: 1;
        transition: opacity 1s ease-out;

        &--hide {
            opacity: 0;
        }
    }
