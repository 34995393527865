
                                        @import "~coreScss/variables/global-variables.scss";
                                        @import "~coreScss/mixins/global-mixins.scss";
                                    

    .c-plan-pill-row {
        $this: &;

        text-align: center;

        &__pill-graphic-wrapper,
        &__pill-graphic {
            width: 100%;
        }

        &,
        &__pill-graphic-section {
            flex-direction: column;
        }

        &,
        &__pill-graphic-wrapper,
        &__pill-graphic-section {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &__pill-graphic-wrapper {
            flex-wrap: nowrap;
            height: 72px;
        }

        &__pill-graphic-section {
            min-width: 115px;
            height: 100%;
            background-color: $nu-primary;
            color: $nu-white;
            flex: 0 1 auto;

            @include breakpoint(medium) {
                min-width: 120px
            }

            &:first-of-type {
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
            }

            &:last-of-type {
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
            }

            &:not(:last-of-type) {
                border-right: 1px solid $nu-white;
            }
        }

    }
