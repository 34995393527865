
                                        @import "~coreScss/variables/global-variables.scss";
                                        @import "~coreScss/mixins/global-mixins.scss";
                                    

    // Defining custom property so transition can be applied
    @property --fade-left {
        syntax: "<number>";
        inherits: false;
        initial-value: 1;
    }
    @property --fade-right {
        syntax: "<number>";
        inherits: false;
        initial-value: 1;
    }

    .c-carousel {
        $this: &;
        position: relative;

        &:focus-within,
        &:hover {
            #{$this}__button {
                &:not(&--disabled-hide) {
                    opacity: 0.8;
                }
            }
        }

        &__container {
            white-space: nowrap;
            display: block;
            outline: none;
            background: inherit;

            @include non-touch-device {
                --fade-left: 1;
                --fade-right: 1;
                mask-size: 100%;
                -webkit-mask-size: 100%;
                mask-repeat: no-repeat;
                -webkit-mask-repeat: no-repeat;
                transition:
                    --fade-left 0.2s ease,
                    --fade-right 0.2s ease;

                $gradient: linear-gradient(
                    to right,
                    rgba(0, 0, 0, var(--fade-left)) 0%,
                    rgba(0, 0, 0, 1) 5%,
                    rgba(0, 0, 0, 1) 95%,
                    rgba(0, 0, 0, var(--fade-right)) 100%
                );
                mask-image: $gradient;
                -webkit-mask-image: $gradient;
            }

            &--fadient-left {
                @include non-touch-device {
                    #{$this}:hover &,
                    #{$this}:focus-within & {
                        --fade-left: 0.2;
                        --fade-right: 1;
                    }
                }
            }

            &--fadient-right {
                @include non-touch-device {
                    #{$this}:hover &,
                    #{$this}:focus-within & {
                        --fade-left: 1;
                        --fade-right: 0.2;
                    }
                }
            }

            &--fadient-both {
                @include non-touch-device {
                    #{$this}:hover &,
                    #{$this}:focus-within & {
                        --fade-left: 0.2;
                        --fade-right: 0.2;
                    }
                }
            }

            &.focus-visible:focus[data-focus-visible-added] {
                outline: none;
            }

            &:not(&--no-margin) {
                @include breakpoint(large) {
                    margin: 0 $nu-spacer-12;
                }
            }

            &--link {
                cursor: pointer;
            }
        }

        &__slide-container {
            display: flex;
            overflow-y: hidden;
            overflow-x: scroll;
            -webkit-overflow-scrolling: touch;
            -ms-overflow-style: none;
            transform: matrix(1, 0, 0, 1, 0, 0);
            scroll-snap-type: x mandatory;
            width: 100%;
            line-height: 0;
            transition-duration: 400ms;
            transition-timing-function: ease;
            padding: 5px 0;
            margin: -5px 0;
            scrollbar-width: none;
            gap: v-bind(slideGapPx);

            &::-webkit-scrollbar {
                display: none;
            }

            &--peek-shift {
                padding-inline-start: $nu-spacer-2;
                padding-inline-end: $nu-spacer-2;
                scroll-padding: 0 $nu-spacer-2;

                @include breakpoint(medium) {
                    padding-inline-start: $nu-spacer-4;
                    padding-inline-end: $nu-spacer-4;
                    scroll-padding: 0 $nu-spacer-4;
                }

                @include breakpoint(large) {
                    padding-inline-start: $nu-spacer-8;
                    padding-inline-end: $nu-spacer-8;
                    scroll-padding: 0 $nu-spacer-8;
                }
            }
        }

        &__slide {
            display: inline-block;
            line-height: normal;
            flex: 0 0 auto;

            &--snap-start {
                scroll-snap-align: start;
            }

            &--snap-start:last-child {
                scroll-snap-align: end;
            }

            scroll-snap-stop: v-bind(scrollSnapStop);

            &--fixed-width {
                width: v-bind(carouselItemWidthPercentage);
            }
        }

        &__button {
            position: absolute;
            top: 0;
            bottom:0;
            margin: auto;

            @include touch-device {
                display: none;

                @include breakpoint(large) {
                    display: flex;
                }
            }

            @include non-touch-device {
                transition: opacity 0.2s ease;
                opacity: 0;

                @include breakpoint(large) {
                    opacity: 1;

                    &--hover-only {
                        opacity: 0;
                    }
                }

            }

            &--left {
                left: 0;

                @include non-touch-device {
                    margin-left: $nu-spacer-2;
                }
            }

            &--left-peeking {
                @include breakpoint(medium) {
                    margin-left: $nu-spacer-4;
                }

                @include breakpoint(large) {
                    margin-left: $nu-spacer-8;
                }
            }

            &--left-far {
                left: $nu-spacer-4;
            }

            &--right {
                right: 0;

                @include non-touch-device {
                    margin-right: $nu-spacer-2;
                }
            }

            &--right-peeking {
                @include breakpoint(medium) {
                    margin-right: $nu-spacer-4;
                }

                @include breakpoint(large) {
                    margin-right: $nu-spacer-8;
                }
            }

            &--right-far {
                right: $nu-spacer-4;
            }
        }

        &__slide-marker-container {
            align-items: center;
            margin-top: $nu-spacer-3;
            display: flex;
            justify-content: center;
            width: 100%;
        }

        &__slide-marker {
            padding: $nu-spacer-0pt5;
            margin-right: $nu-spacer-0pt5;
            cursor: pointer;

            &:last-child {
                margin-right: 0;
            }
        }

        &__slide-marker-dot {
            display: block;
            border-radius: 100%;
            border: 1px solid $nu-primary;
            height: 8px;
            width: 8px;
            transition: background-color 0.2s ease;

            &--current {
                background-color: $nu-primary;
            }
        }
    }
