
                                        @import "~coreScss/variables/global-variables.scss";
                                        @import "~coreScss/mixins/global-mixins.scss";
                                    

    .c-cta-buttons {
        $this: &;

        display: flex;
        flex-direction: column;
        width: 100%;
        pointer-events: none;

        @include breakpoint(medium) {
            display: block;
        }

        &--centered {
            display: flex;
            justify-content: center;
        }

        &--centered-absolute {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }

        &--bottom {
            position: absolute;
            bottom: $nu-spacer-3;
            width: 100%;
        }

        &--vertically-center {
            align-items: center;
            text-align: center;
        }

        &--vertically-right {
            text-align: right;
        }

        &__button-container {
            margin-bottom: $nu-spacer-2;

            &:last-child {
                margin-bottom: 0;
            }

            @include breakpoint(medium) {
                margin-bottom: 0;
                margin-right: $nu-spacer-4;

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        &__button {
            pointer-events: auto;
            &--primary-dark {
                &:hover,
                &:focus,
                &:active {
                    background-color: $nu-white !important;
                    border-color: $nu-primary !important;
                    color: $nu-primary !important;
                }
            }

            &--primary-light {
                &:hover,
                &:focus,
                &:active {
                    border-color: $nu-white !important;
                }
            }

            &--secondary-dark {
                &:hover,
                &:focus,
                &:active {
                    background-color: $nu-white !important;
                }
            }

            &--secondary-light {
                &:hover,
                &:focus,
                &:active {
                    background-color: $nu-white !important;
                    border-color: $nu-primary !important;
                    color: $nu-primary !important;
                }
            }

            &--link-dark,
            &--link-light {
                height: auto;
                padding: 0;
            }
        }

        &--1-up {
            padding: 0 8%;

            @include breakpoint(medium) {
                padding: 0 20%;
            }
        }

        &--2-up {
            padding: 0 8%;

            @include breakpoint(medium) {
                padding: 0 16%;
            }
        }

        &--3-up {
            padding: 0 12%;
        }

        &--4-up {
            padding: 0 8%;
        }
    }
