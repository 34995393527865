
                                        @import "~coreScss/variables/global-variables.scss";
                                        @import "~coreScss/mixins/global-mixins.scss";
                                    

    .c-subscription-plan {
        $this: &;

        text-align: center;

        &__section,
        &__subscription-graphic-wrapper,
        &__subscription-graphic {
            width: 100%;
        }

        &,
        &__subscription-graphic-section {
            flex-direction: column;
        }

        &,
        &__subscription-graphic-wrapper,
        &__subscription-graphic-section {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &__label,
        &__heading {
            display: block;
            margin-bottom: $nu-spacer-1;
        }

        &__subscription-graphic-wrapper {
            margin: 0 0 $nu-spacer-5;
            flex-wrap: nowrap;
            height: 72px;
        }

        &__subscription-graphic-section {
            min-width: 100px;
            width: 33%;
            height: 100%;
            background-color: $nu-primary;
            color: $nu-white;
            flex: 0 1 auto;

            &:first-of-type {
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
            }

            &:last-of-type {
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
            }

            &:not(:last-of-type) {
                border-right: 1px solid $nu-white;
            }
        }

        &__bullets-container {
            text-align: left;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            max-width: 300px;

            @include breakpoint(large) {
                justify-content: unset;
                max-width: unset;
                flex-wrap: nowrap;
            }
        }

        &__bullet-ul {
            flex: 0 0 300px;

            @include breakpoint(large) {
                flex: 1;
                // in large breakpoints, have the width of the checkbox column take up 50% of container minus
                // half the grid row gap so the 2nd group aligns with the 7th column
                max-width: calc(50% - $nu-spacer-1pt5);
            }

            &:first-of-type {
                margin-bottom: $nu-spacer-3;

                @include breakpoint(large) {
                    margin: 0 $nu-spacer-3 0 0;
                }
            }
        }

        &__bullet-text {
            margin-left: $nu-spacer-1pt5;
            flex: 1;
        }

        &__bullet {
            display: flex;
            justify-content: flex-start;

            &:not(:last-of-type) {
                margin-bottom: $nu-spacer-3;
            }
        }
    }
