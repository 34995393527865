
                                        @import "~coreScss/variables/global-variables.scss";
                                        @import "~coreScss/mixins/global-mixins.scss";
                                    

    .c-contentful-block {
        &__row-offset {
            display: none;

            @include breakpoint(medium) {
                display: block;
            }
        }

        &__row {
            &--small-only {
                @include breakpoint(large) {
                    display: none;
                }
            }

            &--large-only {
                display: none;

                @include breakpoint(large) {
                    display: grid;
                }
            }

            &--accordion + &--accordion {
                .c-accordion {
                    border-top: none !important;
                }
            }
        }

        &__column {
            &--card {
                .c-lazyload, .c-async-content-fadein {
                    height: 100%;
                }
            }
        }
    }
