
                                        @import "~coreScss/variables/global-variables.scss";
                                        @import "~coreScss/mixins/global-mixins.scss";
                                    

    .c-video-row-module {
        &__video-row-section {
            &:first-of-type {
                margin-bottom: $nu-spacer-3;
            }

            &:last-of-type {
                margin-bottom: $nu-spacer-8;
            }

            @include breakpoint(large) {
                &:first-of-type {
                    margin-bottom: $nu-spacer-8;
                }
            }

            &--video-wrapper {
                position: relative;
                cursor: pointer;
            }
        }

        &__video-copy-container {
            @include breakpoint(large) {
                display: flex;
                justify-content: center;
                align-items: flex-start;
                flex-direction: column;
                height: 100%;
                padding: 0 $nu-spacer-4;
            }
        }

        &__launcher {
            user-select: none;
            position: absolute;
            left: $nu-spacer-2;
            bottom: $nu-spacer-2;
        }

        &__play-button {
            margin-right: $nu-spacer-1;
        }

        &__fullscreen-video {
            position: fixed;
            left: -999999px;
            top: -999999px;
        }

        &__heading {
            @include heading-3;

            @include breakpoint(large) {
                @include heading-1;
            }
        }
    }
