
                                        @import "~coreScss/variables/global-variables.scss";
                                        @import "~coreScss/mixins/global-mixins.scss";
                                    

    .c-success-check {
        display: inline-flex;
        align-items: center;
        justify-content: center;

        &__icon {
            margin-right: $nu-spacer-1;
        }
    }
