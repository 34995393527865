
                                        @import "~coreScss/variables/global-variables.scss";
                                        @import "~coreScss/mixins/global-mixins.scss";
                                    

    $image-width: 64px;
    $compact-small-image-width: 48px;
    $compact-padding-left: $nu-spacer-2;
    $cta-height: 40px;
    $default-padding: $nu-spacer-5 $nu-spacer-2 $nu-spacer-8;
    $link-container-border: 1px solid $nu-gray--light;

    .c-card-module {
        $this: &;
        background-color: $nu-white;
        width: 100%;
        height: 100%;
        position: relative;
        grid-auto-rows: 1fr;
        text-align: center;

        &--compact-on-small {
            text-align: left;

            @include breakpoint(small) {
                text-align: center;
            }

            #{$this}__container {
                padding: $nu-spacer-2 $nu-spacer-2 $nu-spacer-3;

                @include breakpoint(small) {
                    padding: $default-padding;
                }
            }

            #{$this}__image-container, #{$this}__content-container {
                display: inline-block;
                vertical-align: top;

                @include breakpoint(small) {
                    display: block;
                }
            }

            #{$this}__image-container {
                width: $compact-small-image-width;

                @include breakpoint(small) {
                    margin-bottom: $nu-spacer-2;
                    width: $image-width;
                    margin: 0 auto;
                }
            }

            #{$this}__image {
                width: $compact-small-image-width;

                @include breakpoint(small) {
                    width: $image-width;
                }
            }

            #{$this}__content-container {
                padding-left: $compact-padding-left;
                width: calc(100% - #{$compact-small-image-width} - #{$compact-padding-left});

                @include breakpoint(small) {
                    padding-left: 0;
                    width: 100%;
                }
            }

            #{$this}__heading {
                @include heading-6;
                margin-bottom: $nu-spacer-0pt5;

                @include breakpoint(small) {
                    @include heading-4;
                    margin-bottom: $nu-spacer-1;
                }
            }

            #{$this}__subheading {
                margin-bottom: $nu-spacer-2;

                @include breakpoint(small) {
                    margin-bottom: $nu-spacer-1;
                }
            }

            #{$this}__link-container {
                position: relative;
                height: auto;
                border: none;

                @include breakpoint(small) {
                    position: absolute;
                    height: $cta-height;
                    border-top: $link-container-border;
                }
            }

            #{$this}__link {
                padding: 0;
                width: auto;

                @include breakpoint(small) {
                    padding: $nu-spacer-1 0;
                    width: 100%;
                }
            }
        }

        &--card-with-links {
            text-align: left;

            #{$this}__container {
                padding: $nu-spacer-3;

                @include breakpoint(medium) {
                    padding: $nu-spacer-4 $nu-spacer-5;
                }
            }

            #{$this}__image-container, #{$this}__content-container {
                display: inline-block;
                vertical-align: top;
            }

            #{$this}__image-container {
                width: $compact-small-image-width;
            }

            #{$this}__image {
                width: $compact-small-image-width;
            }

            #{$this}__content-container {
                padding-left: $compact-padding-left;
                width: calc(100% - #{$compact-small-image-width} - #{$compact-padding-left});
            }

            #{$this}__heading {
                margin-bottom: $nu-spacer-0pt5;
            }

            #{$this}__subheading {
                margin-bottom: $nu-spacer-3;
            }

            #{$this}__link-container {
                position: relative;
                line-height: 40px;
                text-align: center;
            }
        }

        &__container {
            padding: $default-padding;
            height: calc(100% - #{$cta-height});
        }

        &__image {
            width: $image-width;
            display: block;
            margin: 0 auto;
        }

        &__heading {
            margin-bottom: $nu-spacer-1;
        }

        &__subheading {
            margin-bottom: $nu-spacer-1;
        }

        &__links-container {
            margin-left: -$nu-spacer-8;
        }

        &__links-li {
            margin-bottom: $nu-spacer-3;

            @include breakpoint(medium) {
                margin-bottom: $nu-spacer-2;
            }

            &:last-of-type {
                margin-bottom: 0;
            }
        }

        &__link-container {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: $cta-height;
            cursor: pointer;
            outline: none;
            border: none;
            border-top: $link-container-border;
            background-color: $nu-white;
        }

        &__link {
            width: 100%;
            background: none;
            border: none;
            cursor: pointer;
            padding: $nu-spacer-1;
            color: $nu-primary;
            @include caption();
        }

        .c-markdown {
            ul {
                text-align: left;
            }
        }
    }
