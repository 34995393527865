
                                        @import "~coreScss/variables/global-variables.scss";
                                        @import "~coreScss/mixins/global-mixins.scss";
                                    

    .c-illustrated-list-item {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        &__illustration {
            background-color: $nu-secondary;
            width: 80px;
            height: 80px;
            border-radius: 50%;
            padding: 16px;
        }

        &__content {
            padding-left: $nu-spacer-2;
        }

    }
